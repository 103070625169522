<template>
    <div class="flex justify-between gap-x-4" v-for="i in 9">
        <div class="flex items-center gap-x-4 flex-grow">
            <p class="w-2">{{ i }}</p>
            <div
                class="h-4 bg-darkGreen-500 flex justify-end items-center pr-1 text-sm text-white"
                :style="{
                    width: `${getBarWitdh(i)}%`,
                }"
            >
                {{ statisticByScoreCount[i] }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Games } from "@/utils/dicts";
import { useUserStatisticsStore } from "~/store/userStatistics";

const { userStatistics } = storeToRefs(useUserStatisticsStore());

const statisticByScoreCount = computed(() => {
    if (!userStatistics.value[Games.Wordle]) return {};

    return userStatistics.value[Games.Wordle].reduce(
        (dict: Record<number, number>, statistic: { score: number }) => {
            if (!dict[statistic.score]) dict[statistic.score] = 0;

            dict[statistic.score]++;

            return dict;
        },
        {} as Record<number, number>
    ) as Record<number, number>;
});

function getBarWitdh(index: number) {
    if (!userStatistics.value[Games.Wordle]) return 0;

    const max = Math.max(...Object.values(statisticByScoreCount.value));

    const pourcentage =
        (userStatistics.value[Games.Wordle].filter(
            (stat: { score: number }) => stat.score === index
        ).length *
            100) /
        max;

    return pourcentage ? pourcentage : 0.3;
}
</script>
